<template>
  <v-container class="pb-2">
    <v-row>
      <v-col cols="1" align="right" offset="11">
        <v-btn icon @click.stop="toggleMenu" class="float-right">
          <v-icon x-large>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <Menu ref="refMenu"></Menu>
    <h2 class="text-center">О компании</h2>

    <h3>ИП Самойлов Евгений Вольдемарович</h3>
    <p>ИНН 616605380104</p>
    <p>ОГРНИП 308616614400023</p>
    <p>344029, г. Ростов-на-Дону, ул. Металлургическая, 15, кв 61</p>

    <v-row>
      <v-col class="d-flex justify-center">
        <router-link to="/" class="text-decoration-none d-block">
          <v-btn
            large
            elevation="2"
            dark
            color="orange lighten-1"
            class="mt-10 custom-btn"
          >
            На главную
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <br><br><br><br><br><br>
  </v-container>
</template>
<script>
import Menu from '../components/Menu'

export default {
  components: { Menu },
  props: ['date', 'token'],
  data: () => ({
    orders: []
  }),
  created () {
    this.orders = this.$ls.get('ordersHistory').reverse()
  },
  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    open (item) {
      this.$router.push('/result/' + item.token + '/' + item.date)
    }
  }
}
</script>
<style>
h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

h3 {
//line-height: 1em;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
//font-weight: normal;
}
</style>
